import { defineStore } from 'pinia'
import type { ICounterparty } from "~/types/ICounterparty";
import type { ICounterpartyContract } from "~/types/ICounterpartyContract";

export const useCounterpartiesStore = defineStore('counterparties', () => {
    const _term = ref('')
    const { status, data } = useAuthState()
    const {
      counterpartyId: counterparty,
      counterpartyContractId: contract,
      reset: resetCookies
    } = useCounterpartyCookie()

  const isStaff = data?.value?.is_staff

    const term = computed({
      get: () => {
        if(isStaff){
          return _term.value
        } else if(!isStaff && _term.value) {
          return null
        }
      },
      set: (value: ICounterparty | string) => {
        if (typeof value === "string" || value === null) {
          _term.value = value === null ? '' : value
          if (_term.value === '') {
            contracts.clear()
          }
        } else if (typeof value === "object") {
          _term.value = value.name
          counterparty.value = value.id
          localStorage.setItem('counterparties', JSON.stringify(value))
          contracts.execute()
        }
      }
    })

    const counterparties = useAuthenticatedFetch<ICounterparty[]>('/api/v1/shop/counterparties', {
      method: 'GET',
      query: { name: term },
      immediate: false,
      watch: false,
    })


    const contracts = useAuthenticatedFetch<ICounterpartyContract[]>(() => `/api/v1/shop/counterparties/${counterparty.value}/contracts`, {
      method: 'GET',
      watch: false,
      immediate: false
    })

    watch(term, (value) => {
      if (value === '') {
        counterparties.clear()
      } else {
        counterparties.execute()
      }
      if (counterparty.value) {
        contracts.execute()
      }
    }, { immediate: true })

    watch(contracts.data, (c) => {
      if (c && Array.isArray(c)) {
        contract.value = c.find((c) => c.is_main)?.id
      }
    })
    watch(status, (value) => {
      if (value === 'unauthenticated') {
        resetCookies()
        _term.value = ''
      }
    })
    return { term, counterparty, contract, contracts, filter: counterparties, resetCookies }
  }
)
